import React from 'react';

const Park = (props: object) => {
  return (
    <svg
      width="53"
      height="53"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M52.3337 48.0142C52.3337 47.7851 52.2427 47.5654 52.0807 47.4033C51.9186 47.2413 51.6989 47.1503 51.4698 47.1503H49.742V21.2335C49.742 21.0044 49.651 20.7846 49.489 20.6226C49.327 20.4606 49.1072 20.3696 48.8781 20.3696H39.3753V18.6418C39.3753 18.4972 39.339 18.3549 39.2697 18.2279C39.2004 18.101 39.1004 17.9935 38.9787 17.9153L27.2807 10.3951V7.41116H36.7836C37.0127 7.41116 37.2324 7.32014 37.3944 7.15813C37.5565 6.99612 37.6475 6.77638 37.6475 6.54726V1.36389C37.6475 1.13478 37.5565 0.915041 37.3944 0.753029C37.2324 0.591017 37.0127 0.5 36.7836 0.5H26.4168C26.1877 0.5 25.968 0.591017 25.806 0.753029C25.644 0.915041 25.5529 1.13478 25.5529 1.36389V10.3951L13.8549 17.9153C13.7333 17.9935 13.6333 18.101 13.564 18.2279C13.4947 18.3549 13.4584 18.4972 13.4584 18.6418V20.3696H3.95558C3.72646 20.3696 3.50672 20.4606 3.34471 20.6226C3.1827 20.7846 3.09168 21.0044 3.09168 21.2335V47.1503H1.36389C1.13478 47.1503 0.915041 47.2413 0.753029 47.4033C0.591017 47.5654 0.5 47.7851 0.5 48.0142V51.4698C0.5 51.6989 0.591017 51.9186 0.753029 52.0807C0.915041 52.2427 1.13478 52.3337 1.36389 52.3337H51.4698C51.6989 52.3337 51.9186 52.2427 52.0807 52.0807C52.2427 51.9186 52.3337 51.6989 52.3337 51.4698V48.0142ZM48.0142 22.0974V47.1503H39.3753V22.0974H48.0142ZM27.2807 2.22779H35.9197V5.68337H27.2807V2.22779ZM15.1862 19.1135L26.4168 11.8939L37.6475 19.1135V47.1503H30.7363V39.3753C30.7363 36.9935 28.7986 35.0558 26.4168 35.0558C24.0351 35.0558 22.0974 36.9935 22.0974 39.3753V47.1503H15.1862V19.1135ZM29.0085 47.1503H23.8252V39.3753C23.8252 37.9464 24.988 36.7836 26.4168 36.7836C27.8457 36.7836 29.0085 37.9464 29.0085 39.3753V47.1503ZM4.81947 22.0974H13.4584V47.1503H4.81947V22.0974ZM50.6059 50.6059H2.22779V48.8781H50.6059V50.6059Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
      <path
        d="M41.1027 23.8258H42.8304V25.5536H41.1027V23.8258ZM44.5582 23.8258H46.286V25.5536H44.5582V23.8258ZM41.1027 27.2814H42.8304V29.0092H41.1027V27.2814ZM44.5582 27.2814H46.286V29.0092H44.5582V27.2814ZM41.1027 30.737H42.8304V32.4648H41.1027V30.737ZM44.5582 30.737H46.286V32.4648H44.5582V30.737ZM41.1027 34.1926H42.8304V35.9204H41.1027V34.1926ZM44.5582 34.1926H46.286V35.9204H44.5582V34.1926ZM41.1027 37.6481H42.8304V39.3759H41.1027V37.6481ZM44.5582 37.6481H46.286V39.3759H44.5582V37.6481ZM6.54688 23.8258H8.27466V25.5536H6.54688V23.8258ZM10.0025 23.8258H11.7302V25.5536H10.0025V23.8258ZM6.54688 27.2814H8.27466V29.0092H6.54688V27.2814ZM10.0025 27.2814H11.7302V29.0092H10.0025V27.2814ZM6.54688 30.737H8.27466V32.4648H6.54688V30.737ZM10.0025 30.737H11.7302V32.4648H10.0025V30.737ZM6.54688 34.1926H8.27466V35.9204H6.54688V34.1926ZM10.0025 34.1926H11.7302V35.9204H10.0025V34.1926ZM6.54688 37.6481H8.27466V39.3759H6.54688V37.6481ZM10.0025 37.6481H11.7302V39.3759H10.0025V37.6481ZM26.4165 28.1453C29.7502 28.1453 32.4637 25.4327 32.4637 22.098C32.4637 18.7634 29.7502 16.0508 26.4165 16.0508C23.0827 16.0508 20.3692 18.7634 20.3692 22.098C20.3692 25.4327 23.0827 28.1453 26.4165 28.1453ZM26.4165 17.7786C28.7982 17.7786 30.7359 19.7163 30.7359 22.098C30.7359 24.4798 28.7982 26.4175 26.4165 26.4175C24.0347 26.4175 22.097 24.4798 22.097 22.098C22.097 19.7163 24.0347 17.7786 26.4165 17.7786Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
      <path
        d="M27.2808 22.0972V18.6416H25.553V21.2333H23.8252V22.9611H26.4169C26.646 22.9611 26.8657 22.8701 27.0277 22.708C27.1898 22.546 27.2808 22.3263 27.2808 22.0972Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
    </svg>
  );
};

export default Park;
