import React from 'react';

const Car = (props: object) => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.4597 17.2356H15.5394C11.3323 17.2356 10.3823 19.3392 9.86208 21.9404L7.93945 31.1011H35.0823L33.1597 21.9404C32.5942 19.3392 31.6668 17.2356 27.4597 17.2356Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.4294 46.0521C38.6103 48.0426 37.027 49.7617 34.9913 49.7617H31.802C29.9698 49.7617 29.721 48.97 29.3817 48.02L29.0424 47.0022C28.5674 45.6225 28.2508 44.6724 25.8079 44.6724H17.1222C14.6794 44.6724 14.3175 45.7356 13.8877 47.0022L13.5484 48.02C13.2318 48.9926 12.9829 49.7617 11.1282 49.7617H7.93889C5.90318 49.7617 4.29726 48.0426 4.50083 46.0521L5.45082 35.7152C5.69963 33.1593 6.17462 31.0784 10.6306 31.0784H32.277C36.7329 31.0784 37.2079 33.1593 37.4567 35.7152L38.4294 46.0521Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91664 26.0122H6.22021"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.756 26.0122H35.0596"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3096 37.8865H16.3989"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.5771 37.8865H31.6664"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.3202 19.7687C42.9536 18.2984 42.7273 16.308 41.5511 14.5663C40.3976 12.8247 38.6333 11.852 37.0273 11.8747"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.3553 22.0081C50.3053 18.5927 49.6267 14.4082 47.2065 10.7892C44.7863 7.17015 41.1672 4.95344 37.6387 4.52368"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Car;
