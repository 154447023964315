/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';
import { DetailsValues } from '@src/components/wizard/register/details';
import { NeighborhoodList } from '@src/interfaces/app/auth/register/renter/renterRegister';
import { workCommuteFormType } from '@src/interfaces/components/wizard/register/workCommute';
import { GeneralInformation } from '@src/interfaces/components/wizard/register/details';
import ParkGarden from '@src/assets/svg/parkGarden';
import Park from '@src/assets/svg/park';
import PointOfInterest from '@src/assets/svg/pointOfInterest';
import Car from '@src/assets/svg/car';

export interface RegisterStepContextDataValue {
  general_information: GeneralInformation;
  neighborhood: NeighborhoodList[];
  work_commute: workCommuteFormType;
  ammenties: string[];
  details: DetailsValues;
}

export interface RegisterStepContextData {
  values: RegisterStepContextDataValue;
  setValues: React.Dispatch<React.SetStateAction<RegisterStepContextDataValue>>;
}

export const defaultRegisterStepContextValue = {
  general_information: {
    first_name: '',
    middle_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    password: '',
    confirm_password: '',
  },
  neighborhood: [
    {
      id: 'nearby_parks',
      svg: ParkGarden,
      name: 'Parks Near By',
    },
    {
      id: 'school_rankings',
      svg: Park,
      name: 'School Rankings',
    },
    {
      id: 'points_of_interest',
      svg: PointOfInterest,
      name: 'Points of Interest',
    },
    {
      id: 'commute_time',
      svg: Car,
      name: 'Commute Time',
    },
  ],
  ammenties: [],
  work_commute: {
    location: {
      address: '',
      place_id: '',
    },
    time: '08:00',
    timeAbb: 'AM',
    timeSecond: '15',
    commuteMethod: '',
    workFromHome: false,
  },
  details: {
    reported_credit: 350,
    reported_salary: 1000,
  },
};

const RegisterStepContext = createContext<RegisterStepContextData>({
  values: defaultRegisterStepContextValue,
  setValues: () => {
    return;
  },
});
export default RegisterStepContext;
